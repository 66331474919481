
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ProductAmount extends Vue {
  @Prop({ default: 0 }) amount!: number;
  @Prop({}) total!: number;
  @Prop({ default: false }) noText!: boolean;
  @Prop({ default: false }) hideQuantity!: boolean;
  @Prop({ default: false }) small!: boolean;
  @Prop({ default: false }) xSmall!: boolean;
}

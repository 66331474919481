var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.getInputColor}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[(_vm.$constants.CONTEXT.FRONTOFFICE !== _vm.context)?_c('v-list',{attrs:{"dense":""}},[(
          _vm.comment.fk_comment == null &&
          _vm.comment.status.name !== _vm.$constants.STATUS.FINISHED
        )?_c('v-list-item',{directives:[{name:"can",rawName:"v-can:update",value:([_vm.$constants.PRIVILEGES['PRODUCT COMMENT']]),expression:"[$constants.PRIVILEGES['PRODUCT COMMENT']]",arg:"update"}],attrs:{"link":""},on:{"click":() => {
            _vm.dialogResolve = true;
          }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc("CommonLayout.resolve")))])],1):_vm._e(),_c('v-list-item',{directives:[{name:"can",rawName:"v-can:delete",value:([_vm.$constants.PRIVILEGES['PRODUCT COMMENT']]),expression:"[$constants.PRIVILEGES['PRODUCT COMMENT']]",arg:"delete"}],attrs:{"link":""},on:{"click":() => {
            _vm.dialogRemove = true;
          }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc("CommonLayout.remove")))])],1)],1):_c('v-list',{attrs:{"dense":""}},[(_vm.comment.fk_comment == null)?_c('v-list-item',{attrs:{"link":""},on:{"click":() => {
            _vm.dialogResolve = true;
          }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc("CommonLayout.resolve")))])],1):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":() => {
            _vm.dialogRemove = true;
          }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc("CommonLayout.remove")))])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogRemove),callback:function ($$v) {_vm.dialogRemove=$$v},expression:"dialogRemove"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 error white--text"},[_vm._v(_vm._s(_vm.$tc("CommonLayout.remove-comment")))]),_c('v-card-text',{staticClass:"py-6 text-center"},[_vm._v(" "+_vm._s(_vm.$tc("CommonLayout.remove-q"))+" ")]),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"same-size-btn mr-2",attrs:{"color":"error"},on:{"click":function($event){_vm.dialogRemove = false}}},[_vm._v(_vm._s(_vm.$tc("Action.cancel")))]),_c('v-btn',{staticClass:"same-size-btn",attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.removeComment}},[_vm._v(_vm._s(_vm.$tc("Action.delete")))])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogResolve),callback:function ($$v) {_vm.dialogResolve=$$v},expression:"dialogResolve"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$tc("CommonLayout.resolve-c")))]),_c('v-divider'),_c('v-card-text',{staticClass:"py-6 text-center"},[_vm._v(" "+_vm._s(_vm.$tc("CommonLayout.resolve-q"))+" ")]),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"same-size-btn mr-2",attrs:{"color":"error"},on:{"click":function($event){_vm.dialogResolve = false}}},[_vm._v(_vm._s(_vm.$tc("Action.cancel")))]),_c('v-btn',{staticClass:"same-size-btn",attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.resolveComment}},[_vm._v(_vm._s(_vm.$tc("CommonLayout.resolve")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import Component, { mixins } from "vue-class-component";
import { Navigation } from "@/mixins/navigation";
import { Prop } from "vue-property-decorator";
import { Notification } from "@/models/notification.interface";
import { UserValidations } from "@/mixins/user-validations";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class CommentToolbar extends mixins(
  Navigation,
  UserValidations,
  StyleCheck
) {
  @Prop() comment!: any;

  $refs!: {
    commentInput: HTMLFormElement;
  };

  loading = false;
  text = "";

  private async sendComment() {
    if (this.text !== "" && this.text !== null) {
      this.loading = true;
      const productComment = {
        text: this.text,
        response: this.comment.id,
      };
      await this.$store
        .dispatch("comments/addCommentToProduct", {
          comment: productComment,
          product_id: this.comment.product.id,
        })
        .then(async () => {
          const Success: Notification = {
            message: this.$tc("CommonLayout.sent"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          this.text = "";
          this.$emit("updateComments");
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("CommonLayout.sent-e"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.$refs.commentInput.focus();
    }
  }
}


import Component, { mixins } from "vue-class-component";
import { Navigation } from "@/mixins/navigation";
import { Prop } from "vue-property-decorator";
import { Notification } from "@/models/notification.interface";
import { UserValidations } from "@/mixins/user-validations";
import { WishlistMixin } from "@/mixins/wishlist";

@Component({})
export default class WishlistButton extends mixins(
  Navigation,
  UserValidations,
  WishlistMixin
) {
  @Prop({ default: false }) icon!: boolean;
  @Prop({ default: "" }) product_id!: string;
  @Prop({ default: false }) isWishlist!: boolean;

  loading = false;

  private async created() {
    await this.getUserWishlist();
  }

  private async addToWishlist(): Promise<void> {
    if (!this.userLogged) {
      this.navigate("/sign-in");
    } else {
      this.loading = true;
      await this.$store
        .dispatch("wishlist/addProductToWishlist", this.product_id)
        .then(async () => {
          await this.getUserWishlist().then(() => {
            const Success: Notification = {
              message: this.$tc("WButton.success"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: false,
            };

            this.$store.dispatch("notifications/showNotification", Success);
          });
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("WButton.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };
          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  private async removeToWishlist(): Promise<void> {
    this.loading = true;
    await this.$store
      .dispatch(
        "wishlist/removeProductFromWishlist",
        this.getWishlistId(this.product_id)
      )
      .then(async () => {
        await this.getUserWishlist().then(() => {
          const Success: Notification = {
            message: this.$tc("WButton.r-success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          if (this.isWishlist) {
            this.$emit("updateWishlist");
          }
        });
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("WButton.r-error"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };
        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private async getUserWishlist() {
    if (this.userLogged) {
      await this.$store
        .dispatch("wishlist/getWishlistByUserToVerify", this.userLogged.id)
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("WButton.g-error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        });
    }
  }
}

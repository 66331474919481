
import { ProductDetail as ProductDetailInterface } from "@/models/detail.interface";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { VueEditor } from "vue2-editor";
import ProductDetailFile from "@/components/Layout/Common/ProductDetailFile.vue";

@Component({
  components: { VueEditor, ProductDetailFile },
})
export default class ProductDetail extends Vue {
  @Prop({ default: { value: "", files: [] } }) detail!: ProductDetailInterface;

  private get swatchStyle() {
    return {
      backgroundColor: this.detail.value,
      cursor: "pointer",
      height: "25px",
      width: "25px",
      borderRadius: "4px",
      border: "1px solid gray",
      transition: "border-radius 200ms ease-in-out",
    };
  }
}
